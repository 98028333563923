@media screen and (max-width: 990px) {
  .mode-selection .p-buttonset {
    flex-wrap: wrap;
  }

  .mode-selection .p-buttonset .p-button {
    flex: none;
    border: 1px solid #ced4da;
    border-radius: 3px !important;
    margin: 2px 0;
  }
}
