.p-column-filter {
    width: 100%;
    max-width: 100%;
}

.datatable-card {
    padding: 0px;
    margin-top: 1rem;
}

.datatable-responsive .p-paginator .p-paginator-current {
    text-align: center;
}

.datatable-responsive .table-header {
    display: flex;
    justify-content: space-between;
}

.datatable-responsive .p-datepicker {
    min-width: 25rem;
}

.datatable-responsive .p-datepicker td {
    font-weight: 400;
}

.datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.datatable-responsive .p-datatable.p-datatable-responsive .p-paginator {
    padding: 1rem;
}

.datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th {
    text-align: left;
}

.datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    cursor: auto;
}

.datatable-responsive .p-datatable.p-datatable-responsive .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td > .p-column-title {
    display: none;
}
.datatable-responsive .p-datatable-responsive .p-treetable-tbody > tr > td > .p-column-title {
    display: none;
}

@media screen and (max-width: 767px) {
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: 0.4rem;
        min-width: 30%;
        display: inline-block;
        margin: -0.4rem 1rem -0.4rem -0.4rem;
        font-weight: bold;
    }

    .datatable-responsive .table-header {
        display: block;
        justify-content: none;
    }
}

@media screen and (max-width: 500px) {
    .p-toolbar {
        display: block !important;
        padding: 0px !important;
    }
    .p-toolbar > * {
        margin: 13px;
    }
}

.p-toolbar-group-left, .p-toolbar-group-right {
    flex-wrap: wrap;
}

.datatable-responsive .actionBody {
    text-align: center;
    width: 100px;
}

.my-split-button > .p-splitbutton-menubutton {
    display: none;
}
.my-split-button > .p-menu-overlay {
    width: 100%;
}

.dialog-md {
    width: 450px;
}
.dialog-md-x {
    width: 500px;
}
.dialog-lg {
    width: 900px;
    max-width: 94%;
}

.p-multiselect-panel {
    width: 200px;
}

.p-datatable-responsive .rowStandard {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
.p-datatable-responsive .rowKPO {
    background-color: rgba(180, 255, 180, 0.75) !important;
}
.p-datatable-responsive .rowManual {
    background-color: rgba(253, 219, 154, 0.75) !important;
}
.p-datatable-responsive .rowEdited {
    background-color: rgba(251, 255, 143, 0.75) !important;
}
.p-datatable-responsive .rowAutomatic {
    background-color: rgb(7, 114, 179, 0.4) !important;
}
.p-datatable-responsive .rowDeleted {
    background-color: rgba(255, 160, 160, 0.75) !important;
}

.p-datatable-responsive .rowTest {
    background-color: rgba(207, 207, 207, 0.75) !important;
}

.p-datatable-responsive .rowStandard:hover {
    background-color: rgb(233, 236, 239) !important;
  }
.p-datatable-responsive .rowManual:hover {
    background-color: rgb(233, 236, 239) !important;
}
.p-datatable-responsive .rowDeleted:hover {
    background-color: rgb(233, 236, 239) !important;
}
.p-datatable-responsive .rowAutomatic:hover {
    background-color: rgb(233, 236, 239) !important;
}
.p-datatable-responsive .rowKPO:hover {
    background-color: rgb(233, 236, 239) !important;
}
.p-datatable-responsive .rowEdited:hover {
    background-color: rgb(233, 236, 239) !important;
}
.p-datatable-responsive .rowTest:hover {
    background-color: rgb(233, 236, 239) !important;
}

.legend > .p-inplace-display {
    font-size: 25px;
    padding: 0px !important;
}
.legend .p-button-outlined {
    background-color: white !important;
    height: 31px;
}

.legend-qube {
    width: 21px;
    height: 21px;
    border-radius: 3px;
    border: 1px solid #ced4da;
}

.p-datatable-responsive tbody > .p-highlight {
    background: #0f97c7 !important;
    color: #ffffff !important;
}
.p-datatable-responsive tbody > .p-highlight:hover {
    background: #0f97c7 !important;
    color: #ffffff !important;
}

.weighing-list .p-datatable-header {
    padding: 0 !important;
}

.p-panel .p-panel-content {
    border: none !important;
}
